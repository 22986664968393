<template>
  <div class="cicc-page">
    <div class="header">
      <div class="header-text">
        <div class="title">首笔投资</div>
        <div class="title">全心全意</div>
      </div>
      <div class="header-desc">“新客特享” 理财省心回报</div>
      <div class="header-desc-hr"></div>
    </div>
    <div class="main">
      <div class="card">
        <div class="card-title">扫码参加活动</div>
        <div class="card-content">
          <div class="card-content-title">有鱼携手【头部券商】带来超值福利</div>
          <div class="card-content-text">
            特享礼包每个交易日9：15-15：30心动上线，
            <br/>
            发行额度内先到先得,详情可扫码咨询
          </div>
        </div>
        <div class="coins">
          <img src="./images/coins.png" alt="">
        </div>
        <div class="packet">
          <img src="./images/papcket.png" alt="">
        </div>
      </div>
      <div class="card welfare-card">
        <div class="card-title">个人福利</div>
        <div class="card-content">
          <div class="card-content-desc">扫码添加工作人员提供 开户/入金相关文件即可领取</div>
          <div class="card-frame">
            <div class="img">
              <img src="./images/vip-card.png" alt="">
            </div>
            <div class="text">
              <div class="first">
                个人<span class="bold">开户</span>福利
              </div>
              <div class="two">
                立得有鱼记账<span class="bold">10天</span>会员
              </div>
            </div>
          </div>
          <div class="card-frame mul-frame">
            <div class="card-frame-title">
              个人<span class="bold">认购</span>福利二选一
            </div>
            <div class="imgs">
              <div class="img1">
                <img src="./images/11.png" alt="">
              </div>
              <div class="img2">
                <img src="./images/33.png" alt="">
              </div>
            </div>
            <ul class="card-frame-text">
              <li>DIY智能温显保温杯</li>
              <li>鱼仔专属100元认购红包</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card welfare-card">
        <div class="card-title">邀请福利</div>
        <div class="card-content">
          <div class="card-content-desc">扫码添加工作人员提供好友相关开户/入金文件即可领取</div>
          <div class="card-frame">
            <div class="img">
              <img src="./images/vip-card.png" alt="">
            </div>
            <div class="text">
              <div class="first">
                邀好友<span class="bold">开户</span>激励
              </div>
              <div class="two">
                每邀请1位好友开户即送<span class="bold">20天</span>有鱼记账会员
                (奖励可累计计算)
              </div>
            </div>
          </div>
          <div class="card-frame last">
            <div class="img">
              <img src="./images/22.png" alt="">
            </div>
            <div class="text">
              <div class="first">
                邀好友<span class="bold">认购</span>激励
              </div>
              <ul class="two">
                <li>邀请人数≤2人</li>
                <li><span class="bold">100元</span>奖金/人</li>
              </ul>
              <ul class="three">
                <li>3≤邀请人数≤5人</li>
                <li><span class="bold">150元</span>奖金/人 </li>
              </ul>
              <ul class="four">
                <li>邀请人数>5人</li>
                  <li><span class="bold">200元</span>奖金/人 </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="gold">
          <img src="./images/gold.png" alt="">
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="gold">
        <img src="./images/gold.png" alt="">
      </div>
      <div class="notice-title">注意事项</div>
      <div class="notice-content">
        <div class="notice-text">①开户/认购/邀请需微信联系工作人员并做登记</div>
        <div class="notice-text">②认购领奖条件（认购期限，认购金额，领奖时间等</div>
        <div class="notice-text">需微信咨询工作人员）</div>
      </div>
    </div>
    <div class="bottom-btn">
      <div class="btn" @click="visible = true">报名领福利</div>
    </div>
    <y-dialog :visible="visible" class="welfare">
      <div class="title" slot="header">扫码领取福利</div>
      <div class="dialog-content">
        <div class="close" @click="visible = false"></div>
        <div class="dialog-desc">回复“资料”即可领取鱼仔精心准备的<br/>
          理财必备知识库</div>
          <div class="qrcode">
            <img :src="qrcodeUrl" alt="">
          </div>
          <div class="dowload-btn" v-if="!isIos" @click="dowloadImage">下载二维码</div>
          <p class="tips-text" v-else>请截图后去微信添加老师</p>
      </div>
    </y-dialog>
  </div>
</template>
<script>
import YDialog from '@/components/dialog';

export default {
  name: 'Cicc',
  components: {
    YDialog,
  },
  created() {
    document.title = '新手理财活动';
  },
  data() {
    return {
      visible: false,
      qrcodeUrl: require('@/assets/images/qrcode.png'),
    };
  },
  computed: {
    isIos() {
      return /i(os|phone|pad)/i.test(navigator.userAgent);
    },
  },
  watch: {
    visible(val) {
      val && window._czc.push(['_trackEvent', '理财活动', '点击', '理财活动报名按钮']);
    },
  },
  methods: {
    dowloadImage1() {
      let url = this.qrcodeUrl;
      let name = '有鱼记账二维码';
      const aLink = document.createElement('a');
      aLink.download = name;
      aLink.href = url;
      aLink.dispatchEvent(new MouseEvent('click', {}));
    },
    dowloadImage() {
      const { host, protocol } = window.location;
      const { qrcodeUrl } = this;
      const imgUrl = `${protocol}//${host}${qrcodeUrl}`;
      console.log(imgUrl);
      window?.android?.saveWebImg(imgUrl);
    },
  },
};
</script>
<style>
  body {
    /* padding-bottom:constant(safe-area-inset-bottom);
    padding-bottom:env(safe-area-inset-bottom); */
  }
</style>
<style scoped lang="scss">
  .cicc-page {
    width: 750px;
    margin: auto;
    background-color: #FC1923;
    .header {
      text-align: center;
      width: 100%;
      height: 1044px;
      background-image:
        url('//download-cos.yofish.com/ad-gongjushiyebu/20220105170739952-head.png');
      background-size: cover;
      background-repeat: no-repeat;
      .header-text {
        font-size: 116px;
        font-family: HarmonyOS Sans SC;
        font-weight: 900;
        font-style: italic;
        color: #FFFFFF;
        line-height: 128px;
        text-shadow: 1px 40px 76px rgba(183, 9, 10, 0.21);
        padding-top: 140px;
      }
      .header-desc {
        font-size: 37px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-style: italic;
        color: #FFFFFF;
        line-height: 81px;
        margin-top: 12px;
      }
      .header-desc-hr {
        width: 390px;
        height: 1PX;
        background: #FEF3E4;
        margin: 5px auto;
      }
    }
    .main {
      .card {
        width: 712px;
        background: linear-gradient(0deg, #FEF3E4 55.00000000000001%, #FADED4 100%);
        border-radius: 24px;
        margin: auto;
        position: relative;
        text-align: center;
        margin-bottom: 103px;
        &:last-child {
          margin-bottom: 0;
          .gold {
            width: 76px;
            position: absolute;
            top: -90px;
            right: 40px;
            img {
              width: 100%;
            }
          }
        }
        &:first-child {
          .coins {
            position: absolute;
            bottom: -160px;
            left: -20px;
          }
          .packet {
            width: 62px;
            position: absolute;
            right: 33px;
            bottom: 19px;
            img {
              width: 100%;
            }
          }
        }
        .card-title {
          width: 560px;
          height: 170px;
          background-size: cover;
          background-image: url('../../../assets/images/ribbon.png');
          position: absolute;
          top: -100px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 42px;
          font-family: HarmonyOS Sans SC;
          font-weight: 900;
          color: #FFFFFF;
          line-height: 200px;
        }
        .card-content {
          padding-top: 80px;
          padding-bottom: 58px;
          .card-content-title {
            font-size: 36px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-style: italic;
            color: #F84D4A;
            line-height: 81px;
          }
          .card-content-text {
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #EA613D;
            line-height: 50px;
          }
          .card-content-desc {
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #F84D4A;
            line-height: 50px;
          }
        }
        .card-frame {
          margin: 42px 31px 0 31px;
          background: #FFFFFF;
          border-radius: 15px;
          display: flex;
          padding: 21px 0;
          align-items: center;
          img {
            width: 100%;
          }
          &.mul-frame {
            display: block;
            .card-frame-title {
              font-size: 26px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-style: italic;
              color: #F84D4A;
              margin-top: 20px;
              .bold {
                font-size: 32px;
              }
            }
            .imgs {
              display: flex;
              align-items: center;
              margin: 30px 66px;
              justify-content: space-between;
              img {
                width: 100%;
              }
              .img1 {
                width: 210px;
              }
              .img2 {
                width: 242px;
              }
            }
            .card-frame-text {
              display: flex;
              margin: 0px 66px 5px 66px;
              justify-content: space-between;
              li {
                // flex: 1;
                font-size: 23px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #EA613D;
                line-height: 50px;
              }
            }
          }
          &.invite-frame {
            // display: flex;/
            // display: flex;
          }
        }
        &.welfare-card {
          .card-content {
            // padding-top: 156px;
            .img {
              width: 208px;
              margin-left: 21px;
              margin-right: 31px;
              flex-shrink: 0;
              img {
                width: 100%;
              }
            }
            .text {
              text-align: left;
              margin-right: 34px;
              .first {
                font-size: 20px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-style: italic;
                color: #F84D4A;
                // line-height: 81px;
                span.bold {
                  font-size: 26px;
                }
              }
              .two, ul {
                font-size: 20px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #EA613D;
                // line-height: 30px;
                margin-top: 5px;
                span.bold {
                  font-size: 23px;
                }
              }
              ul {
                display: flex;
                justify-content: space-between;
                .bold {
                  font-weight: bold;
                }
              }
            }
            .last {
              .text {
                margin-right: 28px;
                flex: 1;
              }
            }
          }
        }
      }
    }
  }
  .bottom {
    // margin-top: 82px;
    text-align: center;
    width: 100%;
    height: 482px;
    background-image: url('./images/bottom-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    .notice-title {
      margin-top: 82px;
      font-size: 30px;
      font-family: HarmonyOS Sans SC;
      font-weight: 900;
      color: #FFFFFF;
      line-height: 36px;
    }
    .notice-text {
      font-size: 25px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 50px;
      &:first-child {
        margin-top: 68px;
      }
    }
    .gold {
      width: 72px;
      position: absolute;
      top: -36px;
      left: 40px;
      img {
        width: 100%;
      }
    }
  }
  .bottom-btn {
    position: fixed;
    bottom: 0px;
    left: 0;
    // margin-bottom: constant(safe-area-inset-bottom);
    // margin-bottom:env(safe-area-inset-bottom);
    // margin-bottom: calc(-40px + env(safe-area-inset-bottom));
    // margin-bottom: calc(-40px + constant(safe-area-inset-bottom));
    background-color: #fff;
    width: 100%;
    padding: 25px 0 40px 0;

  }
  .btn {
    width: 551px;
    height: 99px;
    margin: auto;
    background: linear-gradient(181deg, #FC1923, #FE9437);
    // box-shadow: 0px 3px 68px 2px rgba(201, 36, 10, 0.67),
    //  2px -1px 24px 0px rgba(255, 250, 182, 0.78);
    border-radius: 50px;
    font-size: 38px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    line-height: 99px;
    -webkit-animation-name: scaleDraw;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 3s;
    -webkit-animation-direction: alternate;
  }
  @-webkit-keyframes scaleDraw {
    0% {
      -webkit-transform:scale(1);
    }
    25% {
      -webkit-transform:scale(1.1);
    }
    50% {
      -webkit-transform:scale(1);
    }
    75% {
      -webkit-transform:scale(1.1);
    }
  }
  .welfare {
    /deep/.y-dialog-main {
      height: auto;
      width: 623px;
      background: linear-gradient(0deg, #FEF3E4 55.00000000000001%, #FADED4 100%);
      border-radius: 24px;
      padding-bottom: 50px;
      .close {
        width: 60px;
        height: 60px;
        background-image: url('../../../assets/images/close.png');
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: -120px;
        right: -30px;
      }
      .title {
        width: 560px;
        height: 170px;
        background-size: cover;
        background-image: url('../../../assets/images/ribbon.png');
        position: absolute;
        top: -100px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 42px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 190px;
        text-align: center;
      }
      .dialog-desc {
        margin-top: 100px;
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #EA613D;
        line-height: 50px;
        text-align: center;
      }
      .qrcode {
        width: 274px;
        margin: 53px auto 0;
        img {
          width: 100%;
        }
      }
    }
    .dowload-btn {
      width: 401px;
      height: 72px;
      background: linear-gradient(181deg, #FC1923, #FE9437);
      box-shadow: 2px -1px 24px 0px rgba(255, 250, 182, 0.78);
      border-radius: 36px;
      line-height: 72px;
      text-align: center;
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #FFFFFF;
      margin: 58px auto 0;
    }
  }
  .tips-text {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #EA613D;
    line-height: 50px;
    text-align: center;
    margin-top: 10px;
  }
</style>
